import { intersection } from "lodash";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { State } from "state";
import { Permission } from "../generated/types";

export function useHasPermission() {
    const permissions = useSelector((state: State) => state.app.myUserPermissions) ?? [];
    return useCallback(
        (perm: Permission | Permission[]) => {
            if (Array.isArray(perm)) {
                return intersection(perm, permissions).length > 0;
            } else {
                return permissions.includes(perm);
            }
        },
        [permissions],
    );
}
