import { Reducer } from "@feinarbyte/reduxhelper";
import { SortOrder } from "antd/es/table/interface";

export interface TableState {
    sortField?: string;
    sortOrder?: SortOrder;
    paginationIndex?: number;
}
export interface TableSettings {
    paginationSize?: number;
}

class TableStateReducer extends Reducer<TableState> {
    constructor() {
        super({}, false);
    }
    public changeTableSort(tableId: string, sortField?: string, sortOrder?: SortOrder) {
        if (sortOrder === undefined) {
            delete this.state[tableId];
        } else {
            this.state[tableId] = { ...this.state[tableId], sortField, sortOrder };
        }
    }
    public changeTablePagination(tableId: string, paginationIndex?: number) {
        this.state[tableId] = { ...this.state[tableId], paginationIndex };
    }
}
class TableSettingsReducer extends Reducer<TableSettings> {
    constructor() {
        super({}, true, undefined, undefined, "TableSettingsReducer");
    }
    public changeTablePageSize(tableId: string, paginationSize?: number) {
        this.state[tableId] = { ...this.state[tableId], paginationSize };
    }
}

const TableSettingsReducerInstance = new TableSettingsReducer();
const TableStateReducerInstance = new TableStateReducer();

export const TableSettingsApi = TableSettingsReducerInstance.getApi();
export const TableSettingsReduxReducer = TableSettingsReducerInstance.getReduxReducer();

export const TableStateApi = TableStateReducerInstance.getApi();
export const TableStateReduxReducer = TableStateReducerInstance.getReduxReducer();
