import { UserId } from "@feinarbyte/atom-client";
import { EapAtoms, EapContext } from "./types";

export const atomPathes = {
    [EapAtoms.CategoryInfo]: (name: string) => {
        return [`cat/${EapContext.Code}/${name}`];
    },
    [EapAtoms.ProjectTree]: (name: string) => [`/${name.toLowerCase()}`],
    [EapAtoms.WorkpackageInfo]: (name: string) => {
        return [`wp/${EapContext.Code}/${name}`];
    },
    [EapAtoms.VersionList]: (name: string) => [`/${name.toLowerCase()}`],
    [EapAtoms.Draft]: (name: string) => [`/${name.toLowerCase()}`],
    [EapAtoms.ProjectUsers]: (name: string) => [`/${name.toLowerCase()}`],
    [EapAtoms.PersonalUserData]: (name: string) => {
        return [`usr/${UserId}/${name}`];
    },
    [EapAtoms.ProjectTreeSnapshot]: (name: string) => {
        return [`version/${EapContext.Version}/${name}`];
    },
    [EapAtoms.ProjectActivity]: (name: string) => [`/${name.toLowerCase()}`],
    [EapAtoms.TemplateInfo]: (name: string) => {
        return [`template/${EapContext.TemplateName}/${name}`];
    },
    [EapAtoms.TemplateList]: (name: string) => [`/${name.toLowerCase()}`],
};
