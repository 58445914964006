import useMessage from "antd/es/message/useMessage";
import { createContext, ReactNode, useLayoutEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

interface UnsavedChange {
    type: "todoList" | "workpackage";
    label?: string;
}
export const UnsavedChangesContext = createContext<{
    addUnsavedChange: (type: "todoList" | "workpackage", id: string, label: string) => void;
    removeUnsavedChange: (id: string) => void;
} | null>(null);
export const UnsavedChangesProvider = UnsavedChangesContext.Provider;
export const workpackageDraftSessionStoragePrefix = "wp:draft:";
export const todoListDraftSessionStoragePrefix = "todo:draft:";

interface UnsavedChangesHandlerProps {
    children: ReactNode;
}
export const UnsavedChangesHandler = ({ children }: UnsavedChangesHandlerProps) => {
    const { pathname } = useLocation();
    const [messageApi, contextHolder] = useMessage();
    const [unsavedChanges, setUnsavedChanges] = useState<Record<string, UnsavedChange>>(
        // load unsaved changes from session storage
        Object.entries(sessionStorage).reduce(
            (acc, [key, value]) => {
                try {
                    if (key.startsWith(todoListDraftSessionStoragePrefix)) {
                        const id = key.replace(todoListDraftSessionStoragePrefix, "");
                        acc[id] = { type: "todoList", label: JSON.parse(value).label ?? "" };
                    } else if (key.startsWith(workpackageDraftSessionStoragePrefix)) {
                        const id = key.replace(workpackageDraftSessionStoragePrefix, "");
                        acc[id] = { type: "workpackage", label: JSON.parse(value).label ?? "" };
                    }
                } catch (e) {
                    console.error("Error while loading unsaved changes from session storage:", e);
                }
                return acc;
            },
            {} as Record<string, UnsavedChange>,
        ),
    );
    const addUnsavedChange = (type: "todoList" | "workpackage", id: string, label: string) => {
        setUnsavedChanges((prev) => {
            return { ...prev, [id]: { type, label } };
        });
    };
    const removeUnsavedChange = (id: string) => {
        setUnsavedChanges((prev) => {
            const { [id]: _, ...rest } = prev;
            return rest;
        });
    };
    const unsavedChangesUtils = useMemo(() => {
        return { addUnsavedChange, removeUnsavedChange };
    }, [addUnsavedChange, removeUnsavedChange]);

    useLayoutEffect(() => {
        const unsavedTodoLists = Object.entries(unsavedChanges).filter(
            ([id, change]) => change.type === "todoList" && !pathname.endsWith("/todos/" + id),
        );
        const unsavedWorkpackages = Object.entries(unsavedChanges).filter(
            ([id, change]) => change.type === "workpackage" && !pathname.endsWith("/p/" + id),
        );
        const messageId = "unsaved-changes";

        if (unsavedTodoLists.length > 0 || unsavedWorkpackages.length > 0) {
            void messageApi.open({
                content: (
                    <div className="text-left inline-flex flex-col">
                        <p className="font-bold text-base mb-1">Ungespeicherte Änderungen</p>
                        <ul className="pl-5 mb-0">
                            {unsavedTodoLists.map(([id, change]) => {
                                return (
                                    <li key={id}>
                                        <Link to={"/todos/" + id}>
                                            To-Do Liste{change.label ? ` "${change.label}"` : ""}
                                        </Link>
                                    </li>
                                );
                            })}
                            {unsavedWorkpackages.map(([id, change]) => {
                                return (
                                    <li key={id}>
                                        <Link to={"/p/" + id}>Arbeitspaket {id}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ),
                duration: 0,
                type: "warning",
                key: messageId,
            });
        } else {
            messageApi.destroy(messageId);
        }
    }, [pathname, messageApi, unsavedChanges]);

    return (
        <UnsavedChangesProvider value={unsavedChangesUtils}>
            {contextHolder}
            {children}
        </UnsavedChangesProvider>
    );
};
