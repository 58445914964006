import { Layout, Skeleton } from "antd";
import { Content } from "antd/es/layout/layout";
import "../../style/splashScreen.scss";
import { Header } from "../Header";

export const SplashScreen = () => {
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Header mock />
            <Content>
                <Skeleton active />
            </Content>
        </Layout>
    );
};
