import { ApolloClient } from "@apollo/client";
import { ContextValues } from "@feinarbyte/atom-client";
import { IActivity, ICategory, IDraft, ITemplateInfo, ITestScenario, IVersion, IWorkpackage } from "generated/types";
import gql from "graphql-tag";
import { ITodoList } from "./types";
export const APIClient = {
    Global: {
        pushDraft: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            data: IDraft,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ pushDraft: Promise<boolean> }>({
                    mutation: gql`
                        mutation pushDraft($context: JSON!, $data: JSON!) {
                            pushDraft(context: $context, data: $data)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, data },
                });

                return res!.pushDraft;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        removeDraft: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            data: Partial<IDraft>,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ removeDraft: Promise<boolean> }>({
                    mutation: gql`
                        mutation removeDraft($context: JSON!, $data: JSON!) {
                            removeDraft(context: $context, data: $data)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, data },
                });

                return res!.removeDraft;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        deleteAllDrafts: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ deleteAllDrafts: Promise<boolean> }>({
                    mutation: gql`
                        mutation deleteAllDrafts($context: JSON!) {
                            deleteAllDrafts(context: $context)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext } },
                });

                return res!.deleteAllDrafts;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        createTestScenario: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            testScenario: ITestScenario,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ createTestScenario: Promise<boolean> }>({
                    mutation: gql`
                        mutation createTestScenario($context: JSON!, $testScenario: JSON!) {
                            createTestScenario(context: $context, testScenario: $testScenario)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, testScenario },
                });

                return res!.createTestScenario;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        addWorkpackageDraft: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            parentCode: string,
            extraContext?: ContextValues,
        ): Promise<string> => {
            try {
                const { data: res } = await apolloClient.mutate<{ addWorkpackageDraft: Promise<string> }>({
                    mutation: gql`
                        mutation addWorkpackageDraft($context: JSON!, $parentCode: String!) {
                            addWorkpackageDraft(context: $context, parentCode: $parentCode)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, parentCode },
                });

                return res!.addWorkpackageDraft;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        changeDraftDescription: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            code: string,
            newDescription: string,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ changeDraftDescription: Promise<boolean> }>({
                    mutation: gql`
                        mutation changeDraftDescription($context: JSON!, $code: String!, $newDescription: String!) {
                            changeDraftDescription(context: $context, code: $code, newDescription: $newDescription)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, code, newDescription },
                });

                return res!.changeDraftDescription;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        addCategoryDraft: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            parentCode: string,
            extraContext?: ContextValues,
        ): Promise<string> => {
            try {
                const { data: res } = await apolloClient.mutate<{ addCategoryDraft: Promise<string> }>({
                    mutation: gql`
                        mutation addCategoryDraft($context: JSON!, $parentCode: String!) {
                            addCategoryDraft(context: $context, parentCode: $parentCode)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, parentCode },
                });

                return res!.addCategoryDraft;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        restoreBackup: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            backup: string,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ restoreBackup: Promise<boolean> }>({
                    mutation: gql`
                        mutation restoreBackup($context: JSON!, $backup: String!) {
                            restoreBackup(context: $context, backup: $backup)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, backup },
                });

                return res!.restoreBackup;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        changeDraftCode: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            code: string,
            newCode: string,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ changeDraftCode: Promise<boolean> }>({
                    mutation: gql`
                        mutation changeDraftCode($context: JSON!, $code: String!, $newCode: String!) {
                            changeDraftCode(context: $context, code: $code, newCode: $newCode)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, code, newCode },
                });

                return res!.changeDraftCode;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        changeDraftLabel: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            code: string,
            newLabel: string,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ changeDraftLabel: Promise<boolean> }>({
                    mutation: gql`
                        mutation changeDraftLabel($context: JSON!, $code: String!, $newLabel: String!) {
                            changeDraftLabel(context: $context, code: $code, newLabel: $newLabel)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, code, newLabel },
                });

                return res!.changeDraftLabel;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
    },
    Workpackage: {
        create: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            code: string,
            initialData: Partial<Omit<IWorkpackage, "code">>,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ createWorkpackage2: Promise<boolean> }>({
                    mutation: gql`
                        mutation createWorkpackage2($context: JSON!, $code: String!, $initialData: JSON!) {
                            createWorkpackage2(context: $context, code: $code, initialData: $initialData)
                        }
                    `,
                    fetchPolicy: "no-cache",

                    variables: { context: { ...context, ...extraContext }, code, initialData },
                });

                return res!.createWorkpackage2;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        read: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            extraContext?: ContextValues,
        ): Promise<IWorkpackage | null> => {
            try {
                const { data: res } = await apolloClient.mutate<{
                    readWorkpackage2: Promise<IWorkpackage | null>;
                }>({
                    mutation: gql`
                        mutation readWorkpackage2($context: JSON!) {
                            readWorkpackage2(context: $context)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext } },
                });

                return res!.readWorkpackage2;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        update: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            data: Partial<IWorkpackage>,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ updateWorkpackage2: Promise<boolean> }>({
                    mutation: gql`
                        mutation updateWorkpackage2($context: JSON!, $data: JSON!) {
                            updateWorkpackage2(context: $context, data: $data)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, data },
                });

                return res!.updateWorkpackage2;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        freeze: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ freezeWorkpackage: Promise<boolean> }>({
                    mutation: gql`
                        mutation freezeWorkpackage($context: JSON!) {
                            freezeWorkpackage(context: $context)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext } },
                });

                return res!.freezeWorkpackage;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        unfreeze: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ unfreezeWorkpackage: Promise<boolean> }>({
                    mutation: gql`
                        mutation unfreezeWorkpackage($context: JSON!) {
                            unfreezeWorkpackage(context: $context)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext } },
                });

                return res!.unfreezeWorkpackage;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        delete: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            code: string,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ deleteWorkpackage2: Promise<boolean> }>({
                    mutation: gql`
                        mutation deleteWorkpackage2($context: JSON!, $code: String!) {
                            deleteWorkpackage2(context: $context, code: $code)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, code },
                });

                return res!.deleteWorkpackage2;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        readActivities: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            extraContext?: ContextValues,
        ): Promise<Record<string, IActivity>> => {
            try {
                const { data: res } = await apolloClient.mutate<{ readActivities: Promise<Record<string, IActivity>> }>(
                    {
                        mutation: gql`
                            mutation readActivities($context: JSON!) {
                                readActivities(context: $context)
                            }
                        `,
                        fetchPolicy: "no-cache",
                        variables: { context: { ...context, ...extraContext } },
                    },
                );

                return res!.readActivities;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
    },
    Category: {
        create: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            code: string,
            initialData: Partial<Omit<ICategory, "code">>,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ createCategory2: Promise<boolean> }>({
                    mutation: gql`
                        mutation createCategory2($context: JSON!, $code: String!, $initialData: JSON!) {
                            createCategory2(context: $context, code: $code, initialData: $initialData)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, code, initialData },
                });

                return res!.createCategory2;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        update: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            data: Partial<ICategory>,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ updateCategory2: Promise<boolean> }>({
                    mutation: gql`
                        mutation updateCategory2($context: JSON!, $data: JSON!) {
                            updateCategory2(context: $context, data: $data)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, data },
                });

                return res!.updateCategory2;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        delete: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            code: string,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ deleteCategory2: Promise<boolean> }>({
                    mutation: gql`
                        mutation deleteCategory2($context: JSON!, $code: String!) {
                            deleteCategory2(context: $context, code: $code)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, code },
                });

                return res!.deleteCategory2;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
    },
    Version: {
        create: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            info: Omit<IVersion, "date" | "author">,
            omitDrafts: string[],
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ createVersion: Promise<boolean> }>({
                    mutation: gql`
                        mutation createVersion($context: JSON!, $info: JSON!, $omitDrafts: JSON!) {
                            createVersion(context: $context, info: $info, omitDrafts: $omitDrafts)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, info, omitDrafts },
                });

                return res!.createVersion;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
    },
    Template: {
        create: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            template: string,
            initialData: Partial<Omit<ITemplateInfo, "template">>,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ createTemplateInfo2: Promise<boolean> }>({
                    mutation: gql`
                        mutation createTemplateInfo2($context: JSON!, $template: String!, $initialData: JSON!) {
                            createTemplateInfo2(context: $context, template: $template, initialData: $initialData)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, template, initialData },
                });

                return res!.createTemplateInfo2;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        read: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            extraContext?: ContextValues,
        ): Promise<ITemplateInfo | null> => {
            try {
                const { data: res } = await apolloClient.mutate<{ readTemplateInfo2: Promise<ITemplateInfo | null> }>({
                    mutation: gql`
                        mutation readTemplateInfo2($context: JSON!) {
                            readTemplateInfo2(context: $context)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext } },
                });

                return res!.readTemplateInfo2;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        update: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            data: Partial<Omit<ITemplateInfo, "template">>,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ updateTemplateInfo2: Promise<boolean> }>({
                    mutation: gql`
                        mutation updateTemplateInfo2($context: JSON!, $data: JSON!) {
                            updateTemplateInfo2(context: $context, data: $data)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, data },
                });

                return res!.updateTemplateInfo2;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        delete: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            template: string,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ deleteTemplateInfo2: Promise<boolean> }>({
                    mutation: gql`
                        mutation deleteTemplateInfo2($context: JSON!, $template: String!) {
                            deleteTemplateInfo2(context: $context, template: $template)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, template },
                });

                return res!.deleteTemplateInfo2;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
    },
    TodoList: {
        create: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            initialData: Partial<ITodoList>,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ createTodoList: Promise<boolean> }>({
                    mutation: gql`
                        mutation createTodoList($context: JSON!, $initialData: JSON!) {
                            createTodoList(context: $context, initialData: $initialData)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, initialData },
                });

                return res!.createTodoList;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        delete: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            uuid: string,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ deleteTodoList: Promise<boolean> }>({
                    mutation: gql`
                        mutation deleteTodoList($context: JSON!, $uuid: String!) {
                            deleteTodoList(context: $context, uuid: $uuid)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, uuid },
                });

                return res!.deleteTodoList;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
        update: async (
            apolloClient: ApolloClient<any>,
            onError: (error: any) => void,
            context: ContextValues,
            data: Partial<ITodoList>,
            extraContext?: ContextValues,
        ): Promise<boolean> => {
            try {
                const { data: res } = await apolloClient.mutate<{ updateTodoList: Promise<boolean> }>({
                    mutation: gql`
                        mutation updateTodoList($context: JSON!, $data: JSON!) {
                            updateTodoList(context: $context, data: $data)
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: { context: { ...context, ...extraContext }, data },
                });

                return res!.updateTodoList;
            } catch (error) {
                onError(error);
                throw error;
            }
        },
    },
};
