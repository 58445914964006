import { useParams } from "react-router-dom";
import { EapContext } from "../generated/types";

export type EapContextValues = { [key in EapContext]?: any };

export function useEapContext(): EapContextValues {
    const { code, templateName, todoListId } = useParams<{
        code?: string;
        templateName?: string;
        todoListId?: string;
    }>();
    return {
        [EapContext.Code]: code,
        [EapContext.Version]: "latest",
        [EapContext.TemplateName]: templateName,
        [EapContext.TodoListId]: todoListId,
    };
}
