import { ApolloLink } from "@apollo/client";
import { isDateString, mapValuesDeep } from "@feinarbyte/atom-client";

export const makeRehydrateDatesLink = () => {
    return new ApolloLink((operation, forward) => {
        return forward(operation).map((response) => {
            if (response.data) {
                const r = mapValuesDeep(response.data, (value: any) => (isDateString(value) ? new Date(value) : value));
                response.data = r;
            }
            return response;
        });
    });
};
