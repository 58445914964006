import { Reducer } from "@feinarbyte/reduxhelper";

export interface ModalsState {
    [name: string]: boolean;
}

export const defaultModalsState: ModalsState = {};

class ModalsReducer extends Reducer<ModalsState> {
    constructor() {
        super(defaultModalsState);
    }
    public show(modal: string) {
        this.state[modal] = true;
    }
    public hide(modal: string) {
        this.state[modal] = false;
    }
    public toggle(modal: string) {
        this.state[modal] = !this.state[modal];
    }
}

const ModalsReducerInstance = new ModalsReducer();
export const ModalsApi = ModalsReducerInstance.getApi();
export const ModalsReduxReducer = ModalsReducerInstance.getReduxReducer();
