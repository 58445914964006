import { setContext } from "@apollo/client/link/context";

export const makeAuthLink = (getAuthToken: () => Promise<string | null>) => {
    return setContext(async (_, { headers }) => {
        const token = await getAuthToken();
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            },
        };
    });
};
