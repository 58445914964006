import * as Sentry from "@sentry/react";
import { notification } from "antd";
import { useEffect, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { Permission } from "../generated/types";
import { AppApi } from "../state/app";
import { useThunkDispatch } from "../useThunkDispatch";
import { useAsyncLayoutEffect } from "./useAsyncEffect";

export const useHandleOidcEvents = () => {
    const dispatch = useThunkDispatch();
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    // automatically sign-in
    useAsyncLayoutEffect(async () => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
            try {
                await auth.signinRedirect();
            } catch (e) {
                console.error("Auto Sign-in failed", e);
            }
            setHasTriedSignin(true);
        }

        if (hasTriedSignin && !auth.isAuthenticated) {
            console.error("Auto Sign-in failed");
        }
    }, [auth, hasTriedSignin]);

    useEffect(() => {
        if (auth.isAuthenticated && auth.user) {
            dispatch(
                AppApi.set(
                    "myUserGroups",
                    (auth.user.profile?.["groups"] as string[])?.map((s: string) => s.replace("/", "")),
                ),
            );
            const permissions = auth.user.profile?.resource_access?.["backend"]?.roles;
            if (permissions) {
                dispatch(AppApi.set("myUserPermissions", permissions as Permission[]));
            }
            dispatch(
                AppApi.set("myUserData", {
                    name: auth.user.profile?.["name"] ?? "",
                    email: auth.user.profile?.["email"] ?? "",
                    id: auth.user.profile?.sub ?? "",
                }),
            );
            Sentry.setUser({
                id: auth.user.profile.sub ?? "",
                email: auth.user.profile.email ?? "",
                username: auth.user.profile.name ?? "",
            });
        }
    }, [auth.isAuthenticated, auth.user]);

    useEffect(() => {
        const removeUserSignedOut = auth.events.addUserSignedOut(() => {
            Sentry.setUser(null);
        });
        const removeAccessTokenExpiring = auth.events.addAccessTokenExpiring(async () => {
            try {
                await auth.signinSilent();
            } catch (e) {
                console.error("access token renew failed", e);
            }
        });
        const removeAccessTokenExpired = auth.events.addAccessTokenExpired(() => {
            console.debug("access token expired");
        });

        return () => {
            removeUserSignedOut();
            removeAccessTokenExpiring();
            removeAccessTokenExpired();
        };
    }, [auth.events]);

    useEffect(() => {
        if (auth.error) {
            notification.error({
                placement: "top",
                key: "keycloak-error",
                message: "Authentifizierungsfehler",
                description: <i>{auth.error.message}</i>,
                duration: 0,
            });
            console.error("kc error", auth.error);
        }
    }, [auth.error]);
};
