import { UserId } from "@feinarbyte/atom-client";
import { Avatar } from "antd";
import { AvatarSize } from "antd/es/avatar/AvatarContext";
import { merge } from "lodash";
import { CSSProperties } from "react";
import { EapAtoms } from "../generated/types";
import { useEapAtom } from "../hooks/useEapAtom";
import { consistentStringToColor } from "../util/consistentStringToColor";

interface ProjectUserAvatarProps {
    userId: string;
    style?: CSSProperties;
    size?: AvatarSize;
}

export const ProjectUserAvatar = ({ userId, style, size }: ProjectUserAvatarProps) => {
    const projectUser = useEapAtom(EapAtoms.ProjectUsers, (p) => p.entries.find((u) => u.userId === userId));
    const initials =
        projectUser?.name
            .split(" ")
            .map((p) => p[0])
            .join("") ?? "NA";

    return (
        <Avatar
            className="avatar noWrap"
            style={merge(style, {
                backgroundColor: consistentStringToColor(projectUser?.name ?? "NA"),
            })}
            size={size}
        >
            {initials}
        </Avatar>
    );
};

export const UserAvatar = ({ userId, style, size }: ProjectUserAvatarProps) => {
    const user = useEapAtom(EapAtoms.PersonalUserData, (p) => p.displayName, { [UserId]: userId });
    const initials =
        user
            ?.split(" ")
            .map((p) => p[0])
            .join("") ?? "NA";

    return (
        <Avatar
            className="avatar noWrap"
            style={merge(style, { backgroundColor: consistentStringToColor(user ?? "NA") })}
            size={size}
        >
            {initials}
        </Avatar>
    );
};
