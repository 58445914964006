import { ReactNode } from "react";
import { EapAtoms } from "../generated/types";
import { useEapAtom } from "../hooks/useEapAtom";

interface ProjectTreeLoaderProps {
    children: ReactNode;
}

export const ProjectTreeLoader = ({ children }: ProjectTreeLoaderProps) => {
    useEapAtom(EapAtoms.ProjectTree);

    return <>{children}</>;
};
