import { Reducer } from "@feinarbyte/reduxhelper";

type ConnectionState = "connecting" | "connected" | "disconnected" | "closed" | "terminated";
export interface ApolloWsState {
    state?: ConnectionState;
    subscriptions: string[];
    lazy?: boolean;
    errors: string[];
    fatalError?: boolean;
}

export const defaultApolloWsState: ApolloWsState = {
    subscriptions: [],
    errors: [],
};

export class ApolloWsReducer extends Reducer<ApolloWsState> {
    constructor() {
        super(defaultApolloWsState, false, undefined, undefined, "ApolloReducer");
    }

    addError(error: string): void {
        this.state.errors.push(error);
    }

    addSubscription(subscription: string) {
        if (!this.state.subscriptions.includes(subscription)) {
            this.state.subscriptions.push(subscription);
        }
    }
    removeSubscription(subscription: string) {
        const idx = this.state.subscriptions.indexOf(subscription);

        if (idx > -1) {
            this.state.subscriptions.splice(idx, 1);
        }
    }

    resetErrors(): void {
        this.state.errors = [];
    }
}

const ApolloWsReducerInstance = new ApolloWsReducer();
export const ApolloWsApi = ApolloWsReducerInstance.getApi();
export const ApolloWsReduxReducer = ApolloWsReducerInstance.getReduxReducer();
