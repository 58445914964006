import { Button, Result } from "antd";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";

export const NotFoundView = () => {
    const navigate = useNavigate();

    return (
        <Content style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Result
                status="404"
                title="404"
                subTitle="Diese Seite existiert nicht."
                extra={
                    <Button type="primary" onClick={() => navigate("/")}>
                        Zur Startseite
                    </Button>
                }
            />
        </Content>
    );
};
