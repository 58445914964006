import Button, { ButtonProps } from "antd/es/button/button";
import { useState } from "react";
import { classNames2 } from "../../classNames2";

export function isPromise(value: unknown): value is Promise<unknown> {
    // ref: https://stackoverflow.com/questions/27746304/how-do-i-tell-if-an-object-is-a-promise

    return (
        typeof value === "object" &&
        value !== null &&
        "then" in value &&
        typeof (value as { then: unknown }).then === "function"
    );
}

export type AsyncButtonProps<T = any> = ButtonProps & {
    onSuccess?: (result: T) => void | Promise<void>;
};

export const AsyncButton = ({ onClick, loading: primitiveLoading, onSuccess, ...restProps }: AsyncButtonProps) => {
    const [isHandlingClick, setHandlingClick] = useState<boolean>(false);
    const [errorAnimation, setErrorAnimation] = useState(false);

    return (
        <Button
            {...restProps}
            loading={primitiveLoading === undefined ? isHandlingClick : primitiveLoading}
            className={classNames2(
                () => ({ "async-button-animated": errorAnimation }),
                "async-button",
                restProps.className,
            )}
            onAnimationEnd={() => setErrorAnimation(false)}
            onClick={async (evt: any) => {
                if (typeof onClick === "function" && !isHandlingClick) {
                    const returnValue = onClick(evt) as unknown;

                    if (isPromise(returnValue)) {
                        // If "onClick" function return a Promise
                        // According to the status of Promise, switch loading automatically.
                        try {
                            setHandlingClick(true);
                            const result = await returnValue;
                            if (typeof onSuccess === "function") {
                                await onSuccess(result);
                            }
                            setHandlingClick(false);
                        } catch (e) {
                            setHandlingClick(false);
                            setErrorAnimation(true);
                            throw e;
                        }
                    } else {
                        if (typeof onSuccess === "function") {
                            await onSuccess(returnValue);
                        }
                    }
                }
            }}
        />
    );
};
