import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ITestScenario } from "../generated/types";
import { useApClient } from "../hooks/useApClient";
import { State } from "../state";
import { AppApi } from "../state/app";
import { WbsApi } from "../state/wbs";
import { useThunkDispatch } from "../useThunkDispatch";
interface PlaywrightUtilProviderProps {
    children: React.ReactNode;
}
export const PlaywrightUtilLoader = ({ children }: PlaywrightUtilProviderProps) => {
    const api = useApClient();
    const displaySettings = useSelector((state: State) => state.wbs.displaySettings);
    const dispatch = useThunkDispatch();

    const createTestScenario = async (testScenario: string) => {
        const testScenarioObj: ITestScenario = JSON.parse(testScenario);
        await api.Global.createTestScenario(testScenarioObj);
    };
    const toggleHoverMenu = () =>
        dispatch(WbsApi.set("displaySettings", { ...displaySettings, expertMode: !displaySettings.expertMode }));
    const disableChangeLog = () => dispatch(AppApi.set("doNotShowChangelog", true));

    useEffect(() => {
        globalThis.createTestScenario = createTestScenario;
        globalThis.toggleHoverMenu = toggleHoverMenu;
        globalThis.disableChangeLog = disableChangeLog;
    }, [createTestScenario, toggleHoverMenu, disableChangeLog]);

    return <>{children}</>;
};
